import React from 'react'

export default function index() {
  return <div>This is the Index FIle </div>
}

// import React from 'react'

// import { graphql } from 'gatsby'
// import { Box, Image, Flex, Heading, Text, Styled } from 'theme-ui'

// export default function Home({ data }) {
//   // desctructing all variables for use.

//   return <div></div>
// }

// export const query = graphql``

// const {
//   name: businessName,
//   slug,
// } = data.allGoNationBusinessData.edges[0].node
// const {
//   gonationID,
//   poweredID,
//   seoKeywords,
//   hasHeroTitle,
//   hasAbout,
//   hasMenu,
//   hasMenuImages,
//   hasShout,
//   hasEvents,
//   hasGallery,
//   hasContact,
//   orderOnlineLink,
// } = data.allGoNationData.edges[0].node
// const {
//   description,
//   contact,
//   hours,
// } = data.allGoNationBusinessData.edges[0].node.publishableData
// const { avatar } = data.allGoNationBusinessData.edges[0].node

// const {
//   street,
//   indicator,
//   city,
//   state,
//   postalCode,
//   country,
// } = data.allGoNationBusinessData.edges[0].node.location.address

// export const query = graphql`
//   query MyQuery {
//     allGoNationData {
//       edges {
//         node {
//           gonationID
//           poweredID
//           seoKeywords
//           hasHeroTitle
//           hasAbout
//           hasMenu
//           hasMenuImages
//           hasShout
//           hasEvents
//           hasGallery
//           hasContact
//           orderOnlineLink
//         }
//       }
//     }
//     allGoNationBusinessData {
//       edges {
//         node {
//           name
//           slug
//           location {
//             address {
//               street
//               indicator
//               city
//               state
//               postalCode
//               country
//             }
//           }
//           publishableData {
//             description
//             contact {
//               facebook
//               instagram
//               twitter
//               phone
//               url
//             }
//             hours {
//               monday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//               tuesday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//               wednesday {
//                 close
//                 isClosed
//                 label
//                 isOpen
//                 open
//               }
//               thursday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//               friday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//               saturday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//               sunday {
//                 close
//                 isClosed
//                 isOpen
//                 label
//                 open
//               }
//             }
//           }
//           avatar {
//             image {
//               cloudinaryId
//             }
//           }
//           cover {
//             image {
//               cloudinaryId
//             }
//           }
//         }
//       }
//     }
//   }
// `
